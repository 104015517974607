import { useEffect } from 'react';
import { useRouter } from 'next/router';
import process from "process";

// ----------------------------------------------------------------------

export default function Index() {
  const router = useRouter();

  useEffect(() => {
    const path = window.location.pathname;
    const search = window.location.search; // クエリパラメータを取得

    if (router.pathname === '/') {
      const hasSubDir = router.basePath === '/test' || router.basePath === '/preview';

      if (!hasSubDir) {
        router.push(`${path}${search}`); // クエリパラメータを保持
      } else {
        const newPath = path.replace(new RegExp(`^${router.basePath}`), '');
        router.push(`${newPath}${search}`); // クエリパラメータを保持
      }
    }
  });

  return <></>;
}
